import { ReactElement } from "react";
import dynamic from "next/dynamic";

import {
  Button,
  extendTheme,
  Flex,
  Box,
  Stack,
  Heading,
  Show,
} from "@chakra-ui/react";
import styled from "@emotion/styled";

import NextLink from "next/link";

import Layout from "src/components/Layout";
import {
  IFourZeroFourFields,
  IPageFields,
} from "src/types/generated/contentful";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
//import FourZeroFourLottieFile from "./FourZeroFourLottieFile";
import Image from "next/image";

import img from "./download.png";

const StyleOverride = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: #fff;
  }
`;

const Spacer = styled.div`
  margin-top: 3em;
`;

export default function FourZeroFour(data: IFourZeroFourFields): ReactElement {
  const { body, buttonTitle, buttonLink } = data;
  const buttonLinkf = buttonLink?.fields as IPageFields;
  const slug = buttonLinkf?.slug;

  return (
    <section style={{ background: "#018A97" }}>
      <Layout>
        <Flex
          direction="row"
          align="center"
          justify="center"
          background={"#018A97"}
          p={50}
        >
          <Stack
            direction={"column"}
            spacing={4}
            w={["100%", "50%"]}
            alignItems={["flex-start"]}
            maxW={"400px"}
          >
            {body ? (
              <StyleOverride>{documentToReactComponents(body)}</StyleOverride>
            ) : null}
            <Spacer />
            <NextLink href={`/${slug}`} passHref legacyBehavior>
              <Button size={"lg"} variant="brand-solid-light">
                {buttonTitle}
              </Button>
            </NextLink>
          </Stack>
          <Show above="sm">
            <Flex alignItems="center" justifyContent="center" w={"50%"}>
              <Image src={img} alt="" width={256} height={274} />
            </Flex>
          </Show>
        </Flex>
      </Layout>
    </section>
  );
}
